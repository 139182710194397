import React from "react";
import "./index.css";

const Eula = () => {
  return (
    <div className="terms-and-condition container">
      <h1>GIORGHAM END USER LICENSE AGREEMENT (EULA)</h1>
      <h3 className="color-grey fs-7">Effective Date: 01-01-2025</h3>
      <div className="mt-3 mb-5">
        <p>
          This End User License Agreement constitutes a legally binding
          agreement between User and Giorgham governing your use of the Giorgham
          mobile App. By accessing, downloading, installing, or using the App,
          you affirm your acceptance of and compliance with the terms outlined
          in this Agreement. If you do not agree to these terms, you must not
          use the App.
        </p>
        <br />
        <h5>1. Grant of License </h5>
        <p>
          Subject to the terms of this Agreement, Giorgham grants you a
          non-exclusive, non-transferable, revocable, and limited license to
          download, install, and use the App on compatible devices solely for
          your personal, non-commercial use.
        </p>
        <br />
        <h5>2. Restrictions on Use</h5>
        <p className="ms-3 fs-7 mb-2">You agree not to:</p>
        <ul className="text-line ms-5">
          <li>
            Reverse engineer, decompile, or disassemble the App or any part
            thereof.
          </li>
          <li>
            Modify, adapt, translate, or create derivative works of the App.
          </li>
          <li>
            Rent, lease, sublicense, distribute, or otherwise transfer the App
            to any third party.
          </li>
          <li>
            Use the App to upload, share, or distribute any unlawful, harmful,
            defamatory, or otherwise objectionable content.
          </li>
          <li>
            Exploit the App for commercial purposes without prior written
            authorization from the Company.
          </li>
        </ul>
        <br />
        <h5>3. User-Generated Content </h5>
        <p className="mb-2">
          The App may allow users to upload, post, or share content (“User
          Content”).
        </p>
        <p className="sub-heading mb-1">Ownership and License:</p>
        <p className="mb-3">
          You retain ownership of your User Content; however, by submitting
          content, you grant Giorgham a perpetual, worldwide, royalty-free,
          non-exclusive license to use, reproduce, modify, distribute, and
          display your User Content solely for the operation and promotion of
          the App.
        </p>
        <p className="sub-heading mb-1">Prohibited Content:</p>
        <p>
          Users are prohibited from uploading, posting, or sharing content that
          is:
        </p>
        <ul className="text-line ms-5">
          <li>Offensive, abusive, defamatory, or harassing.</li>
          <li>
            In violation of applicable laws or third-party rights, including
            intellectual property rights.
          </li>
        </ul>
        <p className="sub-heading mb-1">Moderation and Enforcement:</p>
        <ul className="text-line ms-5">
          <li>
            Users may report objectionable content directly within the App.
          </li>
          <li>
            Users can block other users whose content or behavior is deemed
            inappropriate.
          </li>
          <li>
            Giorgham will review reported content and, if necessary, remove it
            or take appropriate action, including suspension or termination of
            offending accounts, within 24 hours of the report.
          </li>
        </ul>
        <br />
        <h5>4. User Obligations</h5>
        <p className="ms-3 fs-7 mb-2">As a User, you agree to:</p>
        <ul className="text-line ms-5">
          <li>
            Provide accurate and complete information during account
            registration.
          </li>
          <li>
            Maintain the confidentiality of your login credentials and notify us
            immediately of any unauthorized access.
          </li>
          <li>
            Comply with all applicable laws, regulations, and the terms of this
            Agreement when using the App.
          </li>
        </ul>
        <br />
        <h5>5. Privacy and Data Collection</h5>
        <p>
          Your use of the App is subject to the Giorgham Privacy Policy, which
          explains how we collect, use, and share your data. By using the App,
          you consent to the practices described in the Privacy Policy.
        </p>
        <br />
        <h5>6. Updates and Modifications</h5>
        <p>
          The Company reserves the right to update, modify, or discontinue the
          App or any of its features at any time without prior notice. Continued
          use of the App following such updates or modifications constitutes
          your acceptance of the changes.
        </p>
        <br />
        <h5>7. Termination</h5>
        <p>
          This Agreement remains effective until terminated by either party.
          Giorgham reserves the right to terminate your access to the App
          without prior notice if you breach any terms of this Agreement. Upon
          termination, you must cease all use of the App and delete it from your
          device.
        </p>
        <br />
        <h5>8. Disclaimer of Warranties</h5>
        <p>
          The App is provided on an “AS IS” and “AS AVAILABLE” basis without any
          warranties, express or implied, including but not limited to
          warranties of merchantability, fitness for a particular purpose, or
          non-infringement. Giorgham does not guarantee that the App will be
          error-free, secure, or uninterrupted.
        </p>
        <br />
        <h5>9. Limitation of Liability</h5>
        <p>
          To the maximum extent permitted by law, Giorgham shall not be liable
          for any direct, indirect, incidental, special, consequential, or
          exemplary damages arising from your use of or inability to use the
          App, including but not limited to loss of profits, data, or goodwill.
        </p>
        <br />
        <h5>10. Governing Law and Jurisdiction</h5>
        <p>
          This Agreement is governed by and construed in accordance with the
          laws of the United States. Any disputes arising from this Agreement
          shall be subject to the exclusive jurisdiction of the courts of the
          United States.
        </p>
        <br />
        <h5>11. Amendments to the Agreement</h5>
        <p>
          Giorgham reserves the right to amend this Agreement at its discretion.
          Any changes will be communicated to Users via updates within the App.
          Your continued use of the App after such amendments constitutes your
          acceptance of the revised Agreement.
        </p>
        <br />
        <h5>12. Contact Information</h5>
        <p>
          If you have any questions or concerns regarding this Agreement, please
          contact us at: Email: lorem@ipsum.com
        </p>
        <p>
          By downloading, installing, or using the Giorgham App, you acknowledge
          that you have read, understood, and agreed to the terms and conditions
          set forth in this End User License Agreement. This version ensures
          formality and clarity while covering all critical aspects of an EULA.
          Let me know if you would like further refinements!
        </p>
      </div>
    </div>
  );
};
export { Eula };
