import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom/dist";
import Logo from "../../assets/images/Logo/Logo.png";

const TopNavBanner = ({ className }) => {
  let location = window.location;
  let pathname = location.pathname;

  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const activeLink = (route) => {
    return pathname === route ? true : false;
  };

  return (
    <div className={`top-nav-bar ${className}`}>
      <Navbar expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} alt="produtc-logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleNavToggle}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="ms-auto"
              defaultActiveKey="/"
              onClick={handleNavToggle}
            >
              <Nav.Item className={"p-3"}>
                <Link
                  to={"/"}
                  onClick={handleNavToggle}
                  className={activeLink("/") ? "active" : ""}
                >
                  Home
                </Link>
              </Nav.Item>
              <Nav.Item className={"p-3"}>
                <Link
                  to={`${location.origin}#feature`}
                  onClick={handleNavToggle}
                  className={activeLink("#feature") ? "active" : ""}
                >
                  Features
                </Link>
              </Nav.Item>
              <Nav.Item className={"p-3"}>
                <Link
                  to={"/about-us"}
                  onClick={handleNavToggle}
                  className={activeLink("/about-us") ? "active" : ""}
                >
                  About us
                </Link>
              </Nav.Item>
              <Nav.Item className={"p-3 "}>
                <Link
                  to={`${location.origin}#reviews`}
                  onClick={handleNavToggle}
                  className={activeLink("#reviews") ? "active" : ""}
                >
                  Reviews
                </Link>
              </Nav.Item>
              <NavLink
                to="/contact-us"
                className={activeLink("/contact-us") ? "active" : ""}
                onClick={handleNavToggle}
              >
                <Button className="btn-theme-default btn-contact-us">
                  contact Us
                </Button>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default TopNavBanner;
